import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import authApi from '@/api/common/auth';
import { mapState } from "vuex";
const queryString = require('query-string');
import ColumnUser from "../../views/components/column/User.vue";
import ColumnBalance from "../../views/components/column/Balance.vue";
import ColumnIpDetails from "../../views/components/column/IpDetails.vue";

export default {

    components: {
        ColumnUser,
        ColumnBalance,
        ColumnIpDetails,
    },

    extends: baseComponent,

    data() {
        return {
            listData: {},
            pagination: {
                querySearch: '',
                username: '',
                action: '',
                orderBy: 'CREATED_AT',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 20,
                outRowsNumber: 0,
            },
            action: '',
            actionList: [],
        };
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
        }),
    },

    created() {
        this.$staffLogStatus.forEach(element => {
            if (this.actionList.find(x => x.label === element.name) !== undefined) {
                this.actionList.find(x => x.label === element.name).value = `${this.actionList.find(x => x.label === element.name).value},${element.status}`
            } else {
                this.actionList.push({
                    label: element.name,
                    value: element.status.toString(),
                    icon: element.icon,
                })
            }
        });
    },

    mounted() {
        this.getData(this.pagination);
    },

    methods: {
        // Action
        performAction(param) {
            switch (param.action) {
                case 'refreshList':
                    this.getData(this.pagination);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },

        // Get data
        getData(request) {
            this.feedStatusLoading(0);
            authApi.getStaffHistory(queryString.stringify(request))
                .then((res) => {
                    try {
                        let responseData = res.data;
                        let pagingItem = responseData.pagingItem;
                        this.listData = responseData.data;
                        this.listData.forEach(element => {
                            element.columnUser = this.mapColumnUser(element);
                            element.columnBalance = this.mapColumnBalance(element);
                            element.columnIpDetails = this.mapColumnIpDetails(element);
                            element.content = JSON.parse(element.content);
                        });
                        this.pagination.orderBy = pagingItem.orderBy;
                        this.pagination.directionSort = pagingItem.directionSort;
                        this.pagination.pageIndex = pagingItem.pageIndex;
                        this.pagination.pageSize = pagingItem.pageSize;
                        this.pagination.outRowsNumber = pagingItem.outRowsNumber;
                        this.feedStatusLoading(1);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(3);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(3);
                });
        },

        sortList() {

        },

        // Sort list
        sortCurrentList(element) {
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            let request = {
                orderBy: element,
                directionSort: this.pagination.directionSort,
                pageIndex: 1,
                pageSize: this.pagination.pageSize,
                outRowsNumber: this.pagination.outRowsNumber,
            };
        },

        // Search list
        searchList() {
            this.pagination.action = this.action.join();
            this.getData(this.pagination);
        },

        // Pagination size change
        handleSizeChange(pageSize) {
            let request = {
                querySearch: this.pagination.querySearch,
                username: this.pagination.username,
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
                pageIndex: 1,
                pageSize: pageSize,
                action: this.pagination.action,
                outRowsNumber: this.pagination.outRowsNumber,
            };
            this.getData(request);
        },

        // Pagination page change
        handleCurrentChange(element) {
            let request = {
                querySearch: this.pagination.querySearch,
                username: this.pagination.username,
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
                pageIndex: element,
                pageSize: this.pagination.pageSize,
                action: this.pagination.action,
                outRowsNumber: this.pagination.outRowsNumber,
            };
            this.getData(request);
        },

        // Map to component
        mapColumnUser(element) {
            let result = {};
            try {
                result = {
                    username: element.username,
                    registerAt: element.userCreatedAt,
                    region: element.region,
                }
            } catch {
                result = {};
            }
            return result;
        },

        mapColumnBalance(element) {
            let result = {};
            try {
                result = {
                    username: element.username,
                    totalRecharge: element.totalRecharge,
                    balance: element.balance,
                    totalImage: element.totalImage,
                    imageThreshold: element.imageThreshold,
                    totalStorage: element.totalStorage,
                    storageThreshold: element.storageThreshold,
                }
            } catch {
                result = {};
            }
            return result;
        },

        mapColumnIpDetails(element) {
            let result = {};
            try {
                if (element.ip === undefined || element.ip === null || element.ip === '') {
                    result.ip = null;
                } else {
                    result.ip = element.ip;
                }
                if (element.ipDetails !== undefined && element.ipDetails !== null && element.ipDetails !== '') {
                    let ipDetails = JSON.parse(element.ipDetails);
                    result.ipDetails = ipDetails;
                }
                if (element.deviceInformation !== undefined &&
                    element.deviceInformation !== null &&
                    element.deviceInformation !== '') {
                    let deviceInformation = JSON.parse(element.deviceInformation);
                    if (deviceInformation.sourceRequest.toLowerCase() === 'web' &&
                        JSON.parse(element.deviceInformation).browserDetails !== undefined &&
                        JSON.parse(element.deviceInformation).browserDetails !== null &&
                        JSON.parse(element.deviceInformation).browserDetails !== '') {
                        result.resolution = deviceInformation.resolutionDevice;
                        let timeZone = deviceInformation.timeZone;
                        if (timeZone === '-0') {
                            timeZone = '+ 0'
                        } else {
                            timeZone = timeZone.replace("+", "+ ").replace("-", "- ");
                        }
                        result.timeZone = timeZone;
                        let browserDetails = JSON.parse(deviceInformation.browserDetails);
                        let browserInfo = this.getBrowserInfo({
                            nAgt: browserDetails.userAgent,
                            appVersion: browserDetails.appVersion,
                        });
                        result.browserName = browserInfo.browserName;
                        result.fullVersion = browserInfo.fullVersion;
                        result.systemOS = browserInfo.systemOS;
                        result.versionOS = browserInfo.versionOS;
                    } else if (deviceInformation.sourceRequest.toLowerCase() === 'android') {
                        result.resolution = null;
                        result.browserName = null;
                        result.fullVersion = null;
                        result.systemOS = deviceInformation.appName;
                        result.versionOS = deviceInformation.readableVersion;
                    }
                } else {
                    result.timeZone = null;
                    result.resolution = null;
                }
            } catch {
                result = {};
            }
            return result;
        },
        getStaffInfoRowStyle(element) {
            let staffItem = this.listStaff.find((x) => x.id === element.createdBy);
            return staffItem && staffItem.email ? 'border-left: 5px solid #239751;' : 'border-left: 5px solid #ffda24'
        },
        getStaffLogStatusImage10000(element) {
            let staffLogStatusInfo = this.$staffLogStatus.find((x) => x.status === element.action);
            if (staffLogStatusInfo) return element.content.IsDeactive ? staffLogStatusInfo.blockedUserIcon : staffLogStatusInfo.unlockedUserIcon;
            else return '';
        },
        getStaffLogStatusImage(element) {
            let staffLogStatusInfo = this.$staffLogStatus.find((x) => x.status === element.action);
            if (staffLogStatusInfo) return staffLogStatusInfo !== undefined ? staffLogStatusInfo.icon : '';
            else return '';
        },
        getLogStatusName(element) {
            let staffLogStatusInfo = this.$staffLogStatus.find((x) => x.status === element.action);
            if (staffLogStatusInfo) return staffLogStatusInfo !== undefined ? staffLogStatusInfo.name : '';
            else return '';
        },
        getDeviceInformation(element) {
            try {
                if (!(element.deviceInformation) || element.deviceInformation === null || element.deviceInformation === '') return '';
                let deviceInformationobj = JSON.parse(element.deviceInformation);
                if (deviceInformationobj.sourceRequest) {
                    return deviceInformationobj.sourceRequest.charAt(0) + deviceInformationobj.sourceRequest.slice(1).toLowerCase();
                }
                return '';
            } catch (error) {
                console.error(error);
                return '';
            }
        },
        getUserDescription(element) {
            let staffLogStatusInfo = this.$staffLogStatus.find((x) => x.status === element.action);
            if (staffLogStatusInfo) {
                return element.content.IsDeactive ? staffLogStatusInfo.blockedUserDescription : staffLogStatusInfo.unlockedUserDescription;
            }
            else return '';
        },
        getProviderStatusIcon(element) {
            let providerStatusInfo = this.$providerStatus.find((x) => x.status.toUpperCase() === element.content.Provider.toUpperCase());
            if (providerStatusInfo) {
                return providerStatusInfo.icon;
            }
            else return '/transactionProvider/others.png';
        },
        getProviderStatusColor(element) {
            let providerStatusInfo = this.$providerStatus.find((x) => x.status.toUpperCase() === element.content.Provider.toUpperCase());
            if (providerStatusInfo) {
                return providerStatusInfo.color;
            }
            else return 'darkorange';
        },
        getProviderStatusName(element) {
            let providerStatusInfo = this.$providerStatus.find((x) => x.status.toUpperCase() === element.content.Provider.toUpperCase());
            if (providerStatusInfo) {
                return providerStatusInfo.name;
            }
            else return 'OTHERS';
        },
        getAmountColor(element) {
            return element.content.Amount === 0 ? '#9E9E9E' : 'forestgreen'
        },
        getPointColor(element) {
            element.content.Point === 0 ? '#9E9E9E' : '#007bff'
        },
        getStaffFullNameCreatedBy(element) {
            let staffInfo = this.listStaff.find((x) => x.id === element.createdBy);
            if (staffInfo) return staffInfo.fullName;
        },
        getStaffEmailCreatedBy(element) {
            let staffInfo = this.listStaff.find((x) => x.id === element.createdBy);
            if (staffInfo) return staffInfo.email;
        },
    }
}